import 'jquery';
import 'popper.js';
import 'bootstrap';

$(document).ready(function() {
  document.body.addEventListener('contextmenu', function(e) {
    e.preventDefault();
  });

  function WHCreateCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `; expires=${date.toGMTString()}`;
    document.cookie = `${name}=${value}${expires}; path=/`;
  }
  
  function WHReadCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  
  function WHCheckCookies() {
    if (WHReadCookie('cookies') !== 'T' && document.querySelector('#accept-cookies-checkbox')) {
      const cookiesMessage = document.getElementById('cookies-message-container');
      cookiesMessage.style.display = 'block';
    }
  }
  
  if (document.querySelector('#accept-cookies-checkbox')) {
    document.getElementById('accept-cookies-checkbox').addEventListener('click', () => {
      WHCreateCookie('cookies', 'T', 365);
      document.getElementById('cookies-message-container')
        .removeChild(document.getElementById('cookies-message'));
    });
  }
  
  window.onload = WHCheckCookies;
});